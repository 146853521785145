body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: relative;

  background-size: cover;
  background-position: center;
}

.head {
  position: absolute;
  left: 0;
  top: 0;

  transition: transform 100ms linear;

}

.head img {
  width: 75px;
}